export const onClientEntry = () => {  
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === 'undefined') {
    /* eslint-disable no-unused-expressions */
    import('intersection-observer');
    console.log('# IntersectionObserver is polyfilled!');
  }
}

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }: { routerProps: { location: Location }, getSavedScrollPosition: (location: Location) => [number, number] | null }) => {
  // Check if there's a hash in the URL
  if (location.hash) {
    // If there's a hash, let the browser handle the scrolling to the anchor
    const element = document.getElementById(location.hash.substring(1));
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
      return false;
    }
  }

  // Otherwise use the saved position or scroll to top
  const currentPosition = getSavedScrollPosition(location);
  
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};
